import React from 'react';
import { inject, observer } from 'mobx-react';
import { NextSeo } from 'next-seo';

import LayoutWithHeaderAndFooter from '../../components/LayoutWithHeaderAndFooter';
import LoginSection from './LoginSection';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { withRedirectToBookings } from '../../hocs/withRedirectToBookings';

@inject(({ store: { i18nUtils } }) => ({
  i18nUtils,
}))
@observer
class LoginPage extends React.Component<WithTranslationType> {
  static async getInitialProps({ lng }): Promise<{ lng: string; namespacesRequired: string[] }> {
    return {
      lng,
      namespacesRequired: ['header', 'login', 'form'],
    };
  }

  render(): JSX.Element {
    const { t } = this.props;

    return (
      <LayoutWithHeaderAndFooter currentRoute="/login" showFooter={false}>
        <NextSeo
          title={t('login_meta-title')}
          description={t('login_meta-description')}
          openGraph={{
            type: 'website',
            site_name: 'Urban',
            url: t('login_og-url'),
            title: t('login_og-title'),
            description: t('login_og-description'),
            images: [
              {
                alt: t('login_og-alt-text'),
                url:
                  'https://res.cloudinary.com/huxr6hrje/image/upload/c_scale,w_192/v1517913336/windows-tile-310x310_tiifpp.png',
              },
            ],
          }}
        />
        <LoginSection />
      </LayoutWithHeaderAndFooter>
    );
  }
}

export default withTranslation('login')(withRedirectToBookings(LoginPage));
